import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "@components/Layout"
import * as Breadcrumb from "@components/Breadcrumb"
import Button from "@components/Button"
import { BsFilePdf } from "react-icons/bs"
import Seo from "@components/Seo"

const PublicationTemplate = ({ data: { previous, next, post } }) => {
  const fields = {
    featuredImage: {
      data: getImage(post.featuredImage?.node?.localFile),
      alt: post.featuredImage?.node?.altText || post.title || ``,
    },
    authors: post.publicationCustomFields?.authoredBy?.map(author => {
      return {
        name: author.title,
        website: author?.contributorCustomFields?.website || null,
        email: author?.contributorCustomFields?.email || null,
        id: author.id,
      }
    }),
  }

  return (
    <Layout>
      <Seo
        yoastSeo={post?.seo}
        image={
          post.featuredImage?.node?.localFile?.childImageSharp?.original?.src
        }
      />
      <article className="">
        <header>
          <div className="bg-slate-100 dark:bg-slate-800">
            <div className="container--boxed-sm py-9 md:py-14">
              <Breadcrumb.Main>
                <Breadcrumb.Item href="/publications/">
                  Publications
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
                <Breadcrumb.Item href={`/publications/${post.slug}/`} active>
                  {post.title}
                </Breadcrumb.Item>
              </Breadcrumb.Main>
              <h1 className="h1 mt-4 md:mt-5 dark:text-slate-200">
                {post.title}
              </h1>
            </div>
          </div>
          <div className="relative">
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="w-full h-3/4 bg-slate-100 dark:bg-slate-800"></div>
            </div>
            <div className="container--boxed-md">
              <GatsbyImage
                image={fields.featuredImage.data}
                alt={fields.featuredImage.alt}
                className="rounded-md shadow-md"
              />
            </div>
          </div>
        </header>
        <aside className="py-9 md:py-14">
          <div className="container--boxed-sm grid grid-cols-1 gap-9">
            {fields.authors?.length > 0 && (
              <div>
                <p className="text-slate-400 mb-3">Authored by</p>
                <ul className="list-none">
                  {fields.authors.map(author => (
                    <li
                      key={author.id}
                      className="text-base mt-2 sm:mt-1 first:mt-0 font-[450] grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-9"
                    >
                      {author.website ? (
                        <a href={author.website}>{author.name}</a>
                      ) : (
                        <span>{author.name}</span>
                      )}
                      {author.email ? (
                        <a
                          href={`mailto:${author.email}`}
                          className="text-slate-400 hover:text-slate-600 transition-colors"
                        >
                          {author.email}
                        </a>
                      ) : (
                        <span></span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <Button
                type="external_link"
                variant="secondary"
                url={post?.publicationCustomFields?.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-2xl mr-2 -ml-1">
                  <BsFilePdf />
                </span>
                Download PDF
              </Button>
            </div>
          </div>
        </aside>
        <div className="container--boxed-sm pb-14">
          <div className="text-slate-400 mb-3">Abstract</div>
          <div
            className="prose dark:prose-invert "
            dangerouslySetInnerHTML={{ __html: post?.content }}
          ></div>
        </div>
      </article>
    </Layout>
  )
}

export default PublicationTemplate

export const pageQuery = graphql`
  query PublicationPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPublication(id: { eq: $id }) {
      ...WpPublicationFields
    }
    previous: wpPublication(id: { eq: $previousPostId }) {
      uri
      slug
      title
    }
    next: wpPublication(id: { eq: $nextPostId }) {
      uri
      slug
      title
    }
  }
`
