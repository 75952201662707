import React from "react"
import { Link } from "gatsby"

type Props = {
  type?: "button" | "internal_link" | "external_link"
  variant?: "primary" | "secondary"
  url?: string
  arrow?: boolean
}

const getButtonComponent = (
  type?: "button" | "internal_link" | "external_link"
) => {
  switch (type) {
    case "internal_link":
      return Link
    case "external_link":
      return "a"
    default:
      return "button"
  }
}

const Button: React.FC<Props> = ({
  type,
  variant,
  url,
  arrow,
  icon,
  children,
  ...props
}) => {
  const ButtonComponent = getButtonComponent(type)
  const className = `button--${variant}`

  return (
    <ButtonComponent
      {...(type === "internal_link" && { ...{ to: url } })}
      {...(type === "external_link" && { ...{ href: url } })}
      className={`${className}`}
      {...props}
    >
      {children}
    </ButtonComponent>
  )
}

export default Button
